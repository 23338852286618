import { Button } from "@/components/ui/button";
import { LucideIcon } from "lucide-react";

interface ButtonWithIconProps {
  icon: LucideIcon;
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

export function ButtonWithIcon({
  icon: Icon,
  onClick,
  disabled = false,
  children,
}: ButtonWithIconProps) {
  return (
    <Button onClick={onClick} disabled={disabled} tw="flex items-center gap-2">
      <Icon />
      {children}
    </Button>
  );
}
