import "twin.macro";

import React from "react";
import { useOrganization } from "../../clients/apiHooks";
import { NeuButton } from "../generic/Neu";
import { Icon } from "../generic";
import { InviteUserPanel } from "./InviteUserPanel";

function OrganizationPage(): JSX.Element {
  const { organizationName, userList } = useOrganization();

  return (
    <div>
      <div tw="border-b border-grey items-center flex py-4 pl-4 justify-between pr-28">
        <span tw="text-3xl font-bold">{organizationName}</span>
        <NeuButton to={"/collection"} icon="mi-arrow-left">
          Back to collections
        </NeuButton>
      </div>
      <div tw={"flex flex-row h-screen"}>
        <div tw="my-8 ml-12 mr-8 flex flex-col w-6/12">
          <div tw="mb-8 flex flex-row items-center justify-between">
            <div tw="text-3xl">Members</div>
            <div tw="font-light">{`${userList.length} members`}</div>
          </div>
          <div tw="divide-solid divide-y divide-grey">
            {userList.map((user) => {
              return (
                <div tw="flex justify-between py-4">
                  <div>
                    <Icon tw="mx-2" t="user" />
                    {user}
                  </div>
                  <div tw="flex flex-row gap-2">
                    <div>Admin</div>
                    {/* <Icon t="user-remove"></Icon>{" "} Currently unusable, as no remove user exists*/}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div tw="grow">
          <InviteUserPanel />
        </div>
      </div>
    </div>
  );
}

export default OrganizationPage;
