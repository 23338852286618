import "twin.macro";
import { useMemo, useState } from "react";
import { NeuButton, NeuInput } from "../generic/Neu";
import { useOrganization } from "../../clients/apiHooks";
import AcknowledgeDialog from "../generic/AcknowledgeDialog";

export function InviteUserPanel() {
  const [emailEntry, setEmailEntry] = useState<string>("");
  const { inviteUsers } = useOrganization();
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState(
    "The users have been invited successfully",
  );

  function handleChange(event: any) {
    setEmailEntry(event.target.value);
  }

  function handleCancel() {
    setEmailEntry("");
  }

  const emails = useMemo(
    () =>
      emailEntry
        .trim()
        .split(/[\s,]+/)
        .filter((e) => e.includes("@")),
    [emailEntry],
  );

  async function handleSubmit() {
    try {
      await inviteUsers.mutate(emails);
      setModalText("");
      setShowModal(true);
      setEmailEntry("The users have been invited successfully");
    } catch (error) {
      console.error("invite users call failed", error);
      setModalText("There was an error, please try to invite the users again");
      setShowModal(true);
    }
  }

  return (
    <div tw="h-full p-4">
      <div tw="flex flex-col">
        <div tw="text-3xl font-bold">Invite New Members</div>
        <div>Invite new members by email to join your organization</div>
      </div>
      <div tw="flex flex-row py-4">
        <div>
          <NeuInput value={emailEntry} onChange={handleChange} />
          <div tw="flex py-4 gap-4">
            <NeuButton onClick={handleCancel}>Cancel</NeuButton>
            <NeuButton onClick={handleSubmit}>{`Send invite`}</NeuButton>
          </div>
          {showModal && (
            <AcknowledgeDialog
              isOpen={showModal}
              body={modalText}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
