import tw from "twin.macro";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  getCollectionTagsKeys,
  getCollectionTagsValues,
  getShareTagsKeys,
  getShareTagsValues,
} from "../../clients/apiClient";

type SuggestionType = "KEY" | "VALUE" | "OPERATOR";

interface PillProps {
  text: string;
  onRemove: (event: React.MouseEvent<HTMLElement>) => void;
  onEdit: (pillText: string, type: SuggestionType) => void;
}

const OPERATORS = ["!=", "="];
const OPERATOR_LABELS: { [key: string]: string } = {
  "=": "is",
  "!=": "is not",
};

const Pill = ({ text, onRemove, onEdit }: PillProps) => {
  const [key, operator, value] = splitKeyOperatorValue(text);

  return (
    <span tw="inline-block border rounded border-[#a8a8a8] px-1 cursor-pointer hover:bg-grey">
      {operator ? (
        <>
          <span onClick={() => onEdit(text, "KEY")}>{key}</span>
          <span tw="pl-1" onClick={() => onEdit(text, "OPERATOR")}>
            {operator}
          </span>
          {value && (
            <span tw="pl-1" onClick={() => onEdit(text, "VALUE")}>
              {value}
            </span>
          )}
        </>
      ) : (
        <span onClick={() => onEdit(text, "KEY")}>{text}</span>
      )}
      <i
        className="mi-close"
        tw="pl-1 cursor-pointer hover:text-danger"
        onClick={onRemove}
      />
    </span>
  );
};

interface InputProps {
  currentQuery: string;
  pillQueries: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveTerm: (index: number) => void;
  inputEl: React.RefObject<HTMLInputElement>;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  onPillEdit: (pillText: string, type: SuggestionType) => void;
}

const Input = ({
  currentQuery,
  pillQueries,
  onChange,
  handleRemoveTerm,
  inputEl,
  onKeyPress,
  onKeyDown,
  onClearSearch,
  onPillEdit,
}: InputProps) => {
  return (
    <div tw="flex justify-between border bg-white rounded w-full gap-1 p-1">
      <div tw="flex flex-wrap gap-1">
        <i className="mi-search" tw="flex items-center" />
        {pillQueries.map((query, index) => (
          <Pill
            key={query}
            text={query}
            onRemove={() => handleRemoveTerm(index)}
            onEdit={onPillEdit}
          />
        ))}
        <input
          type="text"
          value={currentQuery}
          onChange={onChange}
          tw="bg-transparent border-none outline-none grow"
          size={currentQuery.length > 0 ? currentQuery.length : 15}
          ref={inputEl}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          placeholder={pillQueries.length === 0 ? "Search..." : ""}
        />
      </div>
      {pillQueries.length > 0 && (
        <div>
          <span
            tw="inline-block rounded border border-[#a8a8a8] px-1 ml-auto bg-grey text-[#f0f0f0] cursor-pointer hover:(shadow-haptic-small bg-bgbase-dark text-[#0f0f0f])"
            onClick={onClearSearch}
          >
            <i className="mi-backspace" />
          </span>
        </div>
      )}
    </div>
  );
};

const splitKeyOperatorValue = (
  query: string,
): [string] | [string, string, string] => {
  if (query.startsWith("#")) {
    return ["#", "=", query.slice(1)];
  }
  const operator = getOperator(query);
  if (operator) {
    const [key, value] = query.split(operator);
    return [key, operator, value];
  } else {
    return [query];
  }
};

const getOperator = (currentQuery: string): string | null => {
  const operator = OPERATORS.find((op) => currentQuery.includes(op));
  return operator ?? null;
};

interface SuggestionOption {
  text: string;
  label: string;
  type: SuggestionType | "SEARCH";
}

function whatToSuggest(
  hasOperator: boolean,
  shouldSuggestOperators: boolean,
): "KEY" | "OPERATOR" | "VALUE" {
  if (hasOperator) {
    return "VALUE";
  } else if (shouldSuggestOperators) {
    return "OPERATOR";
  } else {
    return "KEY";
  }
}

function getSuggestionOptions(
  hasOperator: boolean,
  suggestionValues: string[],
  shouldSuggestOperators: boolean,
  suggestionKeys: string[],
): SuggestionOption[] {
  let suggestionOptions: SuggestionOption[];

  if (hasOperator) {
    suggestionOptions = suggestionValues.map((text) => ({
      text,
      label: "Value",
      type: "VALUE",
    }));
  } else if (shouldSuggestOperators) {
    suggestionOptions = OPERATORS.map((text) => ({
      text,
      label: OPERATOR_LABELS[text],
      type: "OPERATOR",
    }));
  } else {
    suggestionOptions = suggestionKeys.map((text) => ({
      text,
      label: "Key",
      type: "KEY",
    }));
  }
  return suggestionOptions;
}

interface SuggestionDropdownProps {
  suggestions: SuggestionOption[];
  selectedIndex: number | null;
  handleSuggestionClick: (suggestion: SuggestionOption) => void;
  setSelectedIndex: (index: number) => void;
}

const SuggestionDropdown = ({
  suggestions,
  selectedIndex,
  handleSuggestionClick,
  setSelectedIndex,
}: SuggestionDropdownProps) => {
  return (
    <div>
      {suggestions.map((suggestion, index) => (
        <p
          key={suggestion.text}
          css={[
            tw`flex justify-between cursor-pointer mt-0 p-2`,
            selectedIndex === index && tw`bg-grey`,
          ]}
          onClick={() => handleSuggestionClick(suggestion)}
          onMouseEnter={() => setSelectedIndex(index)}
        >
          {suggestion.text === "=" || suggestion.text === "!=" ? (
            <>
              <span>{suggestion.label}</span>
              <span>{suggestion.text}</span>
            </>
          ) : (
            <>
              <span tw="flex-grow">{suggestion.text}</span>
              <span tw="ml-2 max-w-[50%] overflow-hidden overflow-ellipsis whitespace-nowrap">
                {suggestion.label}
              </span>
            </>
          )}
        </p>
      ))}
    </div>
  );
};

interface SearchInputProps {
  collectionId?: string;
  shareId?: string;
  setSearchQuery: (newValue: string) => void;
  searchQuery: string;
  provideSuggestions?: boolean;
}

function SearchInput({
  collectionId,
  shareId,
  setSearchQuery,
  searchQuery,
  provideSuggestions = true,
}: SearchInputProps) {
  const currentSearch = searchQuery
    .split(" ")
    .filter((term) => term.trim() !== "");

  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [pillQueries, setPillQueries] = useState<string[]>(currentSearch);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const inputEl = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const [shouldSuggestOperators, setShouldSuggestOperators] =
    useState<boolean>(false);

  const [suggestionKeys, setSuggestionKeys] = useState<string[]>([]);
  const [suggestionValues, setSuggestionValues] = useState<string[]>([]);

  function pillifyQuery(currentQuery: string) {
    if (currentQuery) {
      const uniquePillQueries = [...new Set([...pillQueries, currentQuery])];
      setPillQueries(uniquePillQueries);

      setCurrentQuery("");
      setShouldSuggestOperators(false);
    }
  }

  const fetchKeys = useCallback(
    async (query: string) => {
      if (collectionId) {
        const suggestionKeys = await getCollectionTagsKeys(collectionId, query);
        setSuggestionKeys(suggestionKeys.keys);
      } else if (shareId) {
        const shareKeys = await getShareTagsKeys(shareId, query);
        setSuggestionKeys(shareKeys.keys);
      }
    },
    [collectionId, shareId, setSuggestionKeys],
  );

  const fetchValues = useCallback(
    async (query: string) => {
      const [key /* operator */, , valuePrefix] = splitKeyOperatorValue(query);
      if (collectionId) {
        const collectionValues = await getCollectionTagsValues(
          collectionId,
          key,
          valuePrefix ?? "",
        );
        setSuggestionValues(collectionValues.values);
      } else if (shareId) {
        const shareValue = await getShareTagsValues(
          shareId,
          key,
          valuePrefix ?? "",
        );
        setSuggestionValues(shareValue.values);
      }
    },
    [collectionId, shareId, setSuggestionValues],
  );

  const fetchSuggestions = useCallback(
    async (query: string) => {
      const hasOperator = !!getOperator(query);
      const suggest = whatToSuggest(hasOperator, shouldSuggestOperators);
      if (suggest === "KEY") {
        await fetchKeys(query);
      } else if (suggest === "VALUE") {
        await fetchValues(query);
      }
    },
    [fetchKeys, fetchValues, shouldSuggestOperators],
  );

  useEffect(() => {
    if ((!collectionId && !shareId && !isFocused) || !provideSuggestions) {
      return;
    }
    fetchSuggestions(currentQuery);
  }, [
    collectionId,
    shareId,
    currentQuery,
    isFocused,
    fetchSuggestions,
    provideSuggestions,
  ]);

  const suggestionOptions = useMemo(() => {
    const hasOperator = !!getOperator(currentQuery);
    const options = getSuggestionOptions(
      hasOperator,
      suggestionValues,
      shouldSuggestOperators,
      suggestionKeys,
    );

    if (currentQuery) {
      options.push({
        text: `Search for`,
        label: `"${currentQuery}"`,
        type: "SEARCH",
      });
    }

    return options;
  }, [suggestionKeys, suggestionValues, shouldSuggestOperators, currentQuery]);

  const handleRemoveTerm = (index: number) => {
    const newSearchTerms = pillQueries.toSpliced(index, 1);
    setPillQueries(newSearchTerms);
    setSearchQuery(newSearchTerms.join(" "));
  };

  const previousQuery = searchQuery.split(" ").slice(0, -1).join(" ");
  const previousQueryWithSpace = previousQuery ? previousQuery + " " : "";

  const handleSuggestionClick = async (suggestion: SuggestionOption) => {
    setShouldSuggestOperators(false);

    switch (suggestion.type) {
      case "SEARCH":
        pillifyQuery(currentQuery);
        break;
      case "VALUE":
        const [key, operator] = splitKeyOperatorValue(currentQuery);
        if (key && operator) {
          const newQuery = `${key}${operator}${suggestion.text}`;
          setCurrentQuery(newQuery);
          setSearchQuery(`${previousQueryWithSpace}${newQuery}`);
          pillifyQuery(newQuery);
        }
        break;
      case "OPERATOR":
        const newQuery = `${currentQuery}${suggestion.text}`;
        setCurrentQuery(newQuery);
        setSearchQuery(`${previousQueryWithSpace}${newQuery}`);
        break;
      case "KEY":
        setCurrentQuery(suggestion.text);
        setSearchQuery(`${previousQueryWithSpace}${suggestion.text}`);
        setShouldSuggestOperators(true);
        break;
    }

    inputEl.current?.focus();
  };

  const handleKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (selectedIndex !== null && suggestionOptions.length > 0) {
        const selectedSuggestion = suggestionOptions[selectedIndex];
        handleSuggestionClick(selectedSuggestion);
      } else if (currentQuery) {
        pillifyQuery(currentQuery);
      }

      setSelectedIndex(null);
    }
  };

  const handleOnkeyMove = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !suggestionOptions ||
      (suggestionOptions.length === 0 && pillQueries.length === 0)
    ) {
      return;
    }

    if (event.key === "ArrowDown") {
      setSelectedIndex((prevIndex) =>
        prevIndex === null || prevIndex >= suggestionOptions.length - 1
          ? 0
          : prevIndex + 1,
      );
      return;
    }

    if (event.key === "ArrowUp") {
      setSelectedIndex((prevIndex) =>
        prevIndex === null || prevIndex <= 0
          ? suggestionOptions.length - 1
          : prevIndex - 1,
      );
      return;
    }

    if (
      event.key === "Backspace" &&
      currentQuery === "" &&
      pillQueries.length > 0
    ) {
      event.preventDefault();
      const lastPill = pillQueries[pillQueries.length - 1];
      setCurrentQuery(lastPill);

      setPillQueries(pillQueries.slice(0, -1));
    }
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;

    setCurrentQuery(value);
    setSearchQuery([...pillQueries, value].join(" "));
    setShouldSuggestOperators(false);
  };

  const clearSearch = () => {
    setPillQueries([]);
    setSearchQuery("");
    setCurrentQuery("");
  };

  const handleEditPill = (pillText: string, type: SuggestionType) => {
    if (currentQuery.trim()) {
      pillifyQuery(currentQuery);
    }

    setCurrentQuery(pillText);

    setPillQueries((prevPills) =>
      prevPills.filter((pill) => pill !== pillText),
    );

    let cursorPosition = 0;
    const [key, operator, value] = splitKeyOperatorValue(pillText);

    if (type === "KEY") {
      cursorPosition = key.length;
    } else if (type === "OPERATOR") {
      cursorPosition = key.length + operator!.length;
    } else if (type === "VALUE") {
      cursorPosition = key.length + operator!.length + value!.length;
    }

    inputEl.current?.focus();
    setTimeout(() => {
      inputEl.current?.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  return (
    <div
      ref={divRef}
      tw="flex flex-col w-96 relative inline-block shrink cursor-text h-full"
      onBlur={(ev) => {
        if (!divRef.current?.contains(ev.relatedTarget) || !ev.relatedTarget) {
          pillifyQuery(currentQuery);
          setIsFocused(false);
        }
      }}
      onFocus={async () => {
        inputEl.current?.focus();
        setIsFocused(true);
      }}
      tabIndex={0}
    >
      <Input
        currentQuery={currentQuery}
        pillQueries={pillQueries}
        onChange={handleInputChange}
        handleRemoveTerm={handleRemoveTerm}
        inputEl={inputEl}
        onKeyPress={handleKeyPress}
        onKeyDown={(event) => {
          handleOnkeyMove(event);
        }}
        onClearSearch={clearSearch}
        onPillEdit={handleEditPill}
      />
      {isFocused && suggestionOptions.length > 0 && provideSuggestions && (
        <div
          tw="absolute top-full right-0 left-0 w-full border rounded z-10 overflow-auto shadow-card bg-grey-light"
          tabIndex={0}
        >
          <SuggestionDropdown
            suggestions={suggestionOptions}
            selectedIndex={selectedIndex}
            handleSuggestionClick={handleSuggestionClick}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
      )}
    </div>
  );
}

export default SearchInput;
