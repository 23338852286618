import "twin.macro";
import React from "react";
import { NeuButton } from "../generic/Neu";
import { Backdrop } from "../generic";
import { createPortal } from "react-dom";

export interface AcknowledgeDialogProps {
  isOpen: boolean;
  body: React.ReactNode;
  closeText?: string;
  onClose: Function;
}

export default function AcknowledgeDialog({
  isOpen,
  body,
  closeText,
  onClose,
}: AcknowledgeDialogProps) {
  if (isOpen === false) {
    return null;
  }
  return createPortal(
    <>
      <Backdrop onClick={() => onClose()} tw="z-10" />
      <div tw="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0">
        <div tw="shadow-card z-20 mx-4 md:mx-0 py-2">
          <div tw="w-full bg-bgbase flex flex-col justify-center items-center min-w-80 px-4 md:px-8">
            <div tw="p-4 text-center">{body}</div>
            <div tw="p-4 flex md:flex-row justify-around w-full gap-4 flex-col">
              <NeuButton
                onClick={() => onClose()}
                tw="bg-transparent border border-solid border-black text-black"
              >
                {closeText || "Close"}
              </NeuButton>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.body,
  );
}
